import { cn } from "@/client/lib/classnames";
import React from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  leadingIcon?: any;
  label?: string;
  labelDescription?: string;
}

export function InputContainer({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={cn(
        "border dark:border-neutral-700 border-neutral-200 rounded-md flex items-center h-10 p-2 dark:text-white",
        className
      )}
    >
      {children}
    </div>
  );
}

const Input = ({
  value,
  placeholder,
  leadingIcon,
  label,
  labelDescription,
  required = false,
  disabled = false,
  className,
  ...props
}: InputProps) => {
  return (
    <div className={className}>
      {label ? (
        <p
          className={`text-p-s dark:text-white font-medium ${
            !labelDescription ? "mb-2" : null
          }`}
        >
          {label}
          <>{required ? <span className="text-red-600">*</span> : null}</>
        </p>
      ) : null}
      {labelDescription ? (
        <p className="mb-2 leading-tight text-p-s text-neutral-600 dark:text-neutral-400">
          {labelDescription}
        </p>
      ) : null}
      <InputContainer
        className={cn("gap-2 w-full", {
          "bg-neutral-100 dark:bg-neutral-700 dark:text-neutral-400 text-neutral-500":
            disabled,
        })}
      >
        <div className="flex-shrink-0 text-neutral-400 dark:text-neutral-500">
          {leadingIcon ? leadingIcon : null}
        </div>

        <input
          className={`placeholder:text-neutral-400 flex-grow text-p-m overflow-scroll bg-transparent dark:placeholder:text-neutral-500`}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          {...props}
        />
      </InputContainer>
    </div>
  );
};

export default Input;
