import { Collection } from "shared/types/collection";
import { Auction, AuctionSort } from "shared/types/auction";
import { useState, useEffect } from "react";
import Loader from "@/client/components/frame-design-system/loaders/Loader";
import AuctionCard from "../../../frame-design-system/cards/AuctionCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { AssetGrid } from "../CollectionPage";
import { listAuctions } from "@/client/lib/api";
import Link from "next/link";

const LIMIT = 12;

const CollectionAuctionsTab = ({
  collectionId,
  rightSidebarOpen,
}: {
  collectionId: string;
  rightSidebarOpen: boolean;
}) => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [sort, setSort] = useState<AuctionSort>("ENDS_AT_ASC");

  useEffect(() => {
    refreshAuctions();
  }, [sort]);

  useEffect(() => {
    if (!offset) return;
    fetchMoreAuctions(offset);
  }, [offset]);

  const refreshAuctions = async () => {
    setLoading(true);
    setOffset(0);
    const response = await fetchAuctions(0);
    setAuctions(response);
    setLoading(false);
  };

  const handleLoadMore = () => {
    setOffset(offset + LIMIT);
  };

  const fetchMoreAuctions = async (fetchOffset: number) => {
    if (!fetchOffset) return;
    const response = (await fetchAuctions(fetchOffset)) || [];
    setAuctions([...(auctions || []), ...(response || [])]);
  };

  const fetchAuctions = async (fetchOffset: number = 0) => {
    const results = await listAuctions({
      filters: {
        onlyActive: true, // only want to see open valid auctions
        collectionId,
      },
      offset: fetchOffset,

      sort,
      limit: LIMIT,
    });
    setHasMore(results?.data.length >= LIMIT);

    return results?.data;
  };

  return (
    <div className="pb-8 px-8 mt-12">
      {loading ? (
        <div className="h-96 flex flex-col items-center justify-center">
          <Loader size="lg" />
        </div>
      ) : (
        <>
          {!auctions?.length ? (
            <div className="bg-neutral-100 dark:bg-neutral-800 dark:text-white p-8 rounded-xl text-center">
              <p className="text-p-lg font-medium">No Auctions</p>
              <p className="text-neutral-600 dark:text-neutral-400">
                There are currently no auctions for this collection
              </p>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={auctions.length} //This is important field to render the next data
              next={handleLoadMore}
              hasMore={hasMore}
              loader={
                <p className="mx-auto mt-8 text-sm text-center text-neutral-500">
                  Loading more...
                </p>
              }
              endMessage={""}
            >
              <AssetGrid rSidebarOpen={rightSidebarOpen}>
                {auctions?.map((auction: Auction) => {
                  let asset = auction.asset as any;
                  return (
                    (<Link
                      href={`/auctions/${auction.id}`}
                      key={auction.id}
                      className="cursor-pointer">

                      <AuctionCard
                        key={auction.id}
                        assetName={asset?.name}
                        imageUrl={asset?.imageThumbnailUrl}
                        status={auction.status}
                        highestBid={auction.highestBidPrice?.amount.native}
                        reservePrice={auction.reservePrice?.amount.native}
                        endsAt={auction.endsAt}
                        aspectRatio={asset?.aspectRatio}
                      />

                    </Link>)
                  );
                })}
              </AssetGrid>
            </InfiniteScroll>
          )}
        </>
      )}
    </div>
  );
};

export default CollectionAuctionsTab;
