import type { NextPage } from "next";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { isEqual, isNil } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import ms from "ms";
import { useInterval } from "usehooks-ts";

import { Loader } from "client/components/icons/loader";
import TimeSinceLabel from "client/components/common/TimeSinceLabel";
import {
  readCollectionStats,
  listAssets,
  ListAssetParams,
  readCollectionTraits,
} from "client/lib/api";
import AssetCard from "client/components/common/AssetCard/AssetCard";
import MetadataHead from "client/components/common/MetdataHead";
import RightSidebar from "client/components/common/RightSidebar";
import { Collection, CollectionStats } from "shared/types/collection";

import CollectionHeader from "./CollectionHeader";
import FilterSidebar from "./FilterSidebar";
import { generateTokenId } from "shared/artblocks";
import TraitExplore from "./TraitExplore/TraitExplore";
import CenterContainer from "client/components/common/Layout/CenterContainer";
import { PlatformFormat } from "shared/platforms";
import { Filter } from "../../icons/filter";
import TabMenu from "../../frame-design-system/menus/TabMenu";
import Input from "../../frame-design-system/inputs/Input";
import Search from "../../icons/search";
import { ColumnsOne } from "../../icons/columns-one";
import { ColumnsTwo } from "../../icons/columns-two";
import Dropdown from "../../frame-design-system/menus/Dropdown";
import DropdownMenu from "../../frame-design-system/menus/DropdownMenu";
import { Chevron } from "../../icons/chevron";
import Button from "../../frame-design-system/buttons/Button";

import CollectionAuctionsTab from "./tabs/CollectionAuctions";
import CollectionActivityTab from "./tabs/CollectionActivity";
import CollectionOverview from "./tabs/CollectionOverview/CollectionOverview";
import {
  ProjectSaleManagerMachineContext,
  ProjectSaleManagerMachineContextProvider,
} from "./ProjectSaleManagerMachineContext";
import { deserializeSnapshot } from "@artblocks/sdk/dist/machines/project-sale-manager-machine";
import { useArtBlocksClient } from "../../common/ArtBlocksProvider";

export enum GridSize {
  Large = "large",
  Small = "small",
}

export type Props = {
  collection: Collection;
  collectionId: string;
  onlyBuyNow: boolean;
  onlySansa: boolean;
  sort: { title: string; sortKey: string };
  preSelectedTraits?: any;
  preSelectedCustomTraits?: any;
  preSelectedExploredTrait?: string;
  rankless?: boolean;
  metadata?: MetadataHead;
  mintedOut?: boolean;
  aspectRatioIsVariable?: boolean;
  customTraits?: any;
  artBlocksHasuraProjectId?: string;
  artBlocksPurchaseMachineInitialSnapshot?: string;
};

/**
 * This component acts as a wrapper for the CollectionPage, providing it with
 * necessary context via the PurchaseMachineContext.Provider. It passes down
 * props including project ID and clients for public and wallet interactions, as
 * well as an initial state snapshot for the purchase machine. The snapshot
 * mechanism is used to initialize the state machine with server-fetched data to
 * prevent UI flickers/flashes during client-side hydration.
 *
 * @param props.artBlocksHasuraProjectId - The project ID for the ArtBlocks
 * project as stored in the Art Blocks database
 * @param props.artBlocksPurchaseMachineInitialSnapshot - This parameter is a
 * serialized snapshot representing the initial state of the purchase machine.
 * It is expected that this snapshot already includes the initial project data,
 * assuming the project is valid. This preloaded state helps in initializing the
 * purchase machine directly in the correct state, preventing UI inconsistencies
 * during client-side hydration.
 */
export const CollectionPageWrapper: NextPage<Props> = ({
  artBlocksHasuraProjectId,
  artBlocksPurchaseMachineInitialSnapshot,
  ...props
}: Props) => {
  const artblocksClient = useArtBlocksClient();
  const snapshot = artBlocksPurchaseMachineInitialSnapshot
    ? deserializeSnapshot({
        snapshot: JSON.parse(artBlocksPurchaseMachineInitialSnapshot ?? "{}"),
        artblocksClient,
      })
    : undefined;

  return (
    <ProjectSaleManagerMachineContextProvider
      artblocksProjectId={artBlocksHasuraProjectId}
      snapshot={snapshot}
    >
      <CollectionPage {...props} />
    </ProjectSaleManagerMachineContextProvider>
  );
};

const CollectionPage: NextPage<Props> = ({
  collectionId,
  collection,
  onlyBuyNow: presetOnlyBuyNow,
  onlySansa: presetOnlySansa,
  sort,
  preSelectedTraits,
  preSelectedExploredTrait,
  metadata,
  rankless,
  mintedOut,
  aspectRatioIsVariable,
  customTraits,
}: Props) => {
  const router = useRouter();

  const [collectionStats, setCollectionStats] = useState<CollectionStats>();

  const ASSET_LIMIT = mintedOut ? 24 : 100;
  const ASSET_REFRESH_INTERVAL_MS = mintedOut ? ms("30s") : ms("5s");
  const STATS_REFRESH_INTERVAL_MS = ms("30s");
  const TRAIT_REFRESH_INTERVAL_MS = ms("30s");

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [traits, setTraits] = useState<any>(null);
  const [onlyBuyNow, setOnlyBuyNow] = useState<boolean>(presetOnlyBuyNow);
  const [onlySansa, setOnlySansa] = useState<boolean>(presetOnlySansa || false);
  const [assetGridSize, setAssetGridSize] = useState<GridSize>(GridSize.Large);
  const [assetSort, setAssestSort] = useState<any>(sort);
  const [assetSortOpen, setAssetSortOpen] = useState<boolean>(false);
  const [assets, setAssets] = useState<any>(null);
  const [assetsLoading, setAssetsLoading] = useState<boolean>(true);
  const [assetsTotal, setAssetsTotal] = useState<number | null>(null);
  const [assetsOffset, setAssetsOffset] = useState<number>(0);
  const [assetsHasMore, setAssetsHasMore] = useState<boolean>(true);
  const [assetsLastUpdated, setAssetsLastUpdated] = useState<Date>(new Date());
  const [isAutoRefreshOn, setIsAutoRefreshOn] = useState<boolean>(true);
  const [rightSidebarOpen, setRightSidebarOpen] = useState<boolean>(true);
  const [showRankings, setShowRankings] = useState<boolean>(!rankless);
  const [filteredToken, setFilteredToken] = useState<string>("");

  const [generatorAsset, setGeneratorAsset] = useState<number | null>(null);
  const [exploredTrait, setExploredTrait] = useState<any>(
    preSelectedExploredTrait || null
  );
  const [selectedTraits, setSelectedTraits] = useState<{
    [traitType: string]: {
      [traitValueString: string]: {
        [value: string]: string | number | boolean;
      };
    };
  }>(preSelectedTraits || {});

  // const [customTraits, setCustomTraits] = useState<any>(null);
  const [selectedCustomTraits, setSelectedCustomTraits] = useState<{
    [traitType: string]: {
      [traitValueString: string]: {
        [value: string]: string | number | boolean;
      };
    };
  }>({});

  const sortOptions = {
    items: [
      {
        title: "Price: Low to High",
        type: "button",
        onClick: () =>
          setAssestSort({
            title: "Price: Low to High",
            sortKey: "FLOOR_PRICE_ASC",
          }),
      },
      {
        title: "Price: High to Low",
        type: "button",
        onClick: () =>
          setAssestSort({
            title: "Price: High to Low",
            sortKey: "FLOOR_PRICE_DESC",
          }),
      },
      {
        title: "Rarity: Rare to Common",
        type: "button",
        onClick: () =>
          setAssestSort({
            title: "Rarity: Rare to Common",
            sortKey: "RARITY_ASC",
          }),
      },
      {
        title: "Rarity: Common to Rare",
        type: "button",
        onClick: () =>
          setAssestSort({
            title: "Rarity: Common to Rare",
            sortKey: "RARITY_DESC",
          }),
      },
      {
        title: "Index: Low to High",
        type: "button",
        onClick: () =>
          setAssestSort({
            title: "Index: Low to High",
            sortKey: "TOKEN_INDEX_ASC",
          }),
      },
      {
        title: "Index: High to Low",
        type: "button",
        onClick: () =>
          setAssestSort({
            title: "Index: High to Low",
            sortKey: "TOKEN_INDEX_DESC",
          }),
      },
    ],
  } as any;

  useEffect(() => {
    // fetchCollection();
    fetchTraits();
    refreshAssets();
    refreshStats();
  }, [collectionId]);

  useInterval(
    () => {
      refreshLatestAssets();
    },
    // if we are purchasing dont refresh the data as this can trigger the purchase modal to close
    isAutoRefreshOn ? ASSET_REFRESH_INTERVAL_MS : null
  );

  useInterval(() => {
    refreshStats();
  }, STATS_REFRESH_INTERVAL_MS);

  useInterval(() => {
    fetchTraits();
  }, TRAIT_REFRESH_INTERVAL_MS);

  // if exploring a trait is set make sure any preselected trait filters are cleared
  useEffect(() => {
    if (isNil(exploredTrait)) return;
    setSelectedTraits({});
    setSelectedCustomTraits({});
  }, [exploredTrait]);

  useEffect(() => {
    updateUrlQuery();
    refreshAssets();
  }, [
    onlyBuyNow,
    onlySansa,
    assetSort,
    selectedTraits,
    selectedCustomTraits,
    exploredTrait,
  ]);

  useEffect(() => {
    updateUrlQuery();
  }, [showRankings]);

  const updateUrlQuery = () => {
    const { id, tab, ...existingQueryParams } = router.query;
    const newQueryParams = buildPushQuery();

    // if the query match dont try to replace
    if (isEqual(existingQueryParams, newQueryParams)) return;

    router.replace(
      {
        pathname: router.pathname,
        query: {
          id,
          tab,
          ...newQueryParams,
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  useEffect(() => {
    fetchMoreAssets();
  }, [assetsOffset]);

  // Handle token search
  useEffect(() => {
    refreshAssets();
  }, [filteredToken]);

  const buildPushQuery = () => {
    let query = {} as any;

    if (onlySansa) {
      query["only-sansa"] = true;
    }

    if (onlyBuyNow) {
      query["buy-now"] = true;
    }

    if (assetSort.sortKey === "FLOOR_PRICE_DESC") {
      query.sort = "FLOOR_PRICE_DESC";
    }

    if (!showRankings) {
      query.rankless = true;
    }

    // check selected trait filters exist
    if (Object.keys(selectedTraits).length !== 0) {
      let attributes = [] as any;

      Object.keys(selectedTraits).map((traitType: string) => {
        let categoryObj = { name: traitType, values: [] } as any;
        Object.keys(selectedTraits[traitType]).map(
          (traitValueString: string) => {
            const value = selectedTraits?.[traitType]?.[traitValueString].value;
            categoryObj["values"].push(value);
          }
        );
        attributes.push(categoryObj);
      });

      query["attributes"] = JSON.stringify(attributes);
    }

    if (exploredTrait) {
      query.explore = exploredTrait;
    }

    return query;
  };

  const fetchAssets = async (offset: number) => {
    if (!collectionId) return;

    let params = buildAssetParams(offset);
    let response = await listAssets(params);

    setAssetsTotal(response?.total);
    setAssetsHasMore(response?.data?.length >= ASSET_LIMIT);
    setAssetsLoading(false);
    return response?.data;
  };

  const refreshAssets = async () => {
    setAssetsLoading(true);
    if (!collectionId) return;

    // reset offset
    setAssetsOffset(0);

    const response = await fetchAssets(0);
    setAssets(response);
    setAssetsLoading(false);
  };

  const refreshStats = async () => {
    if (!collectionId) return;
    let response = await readCollectionStats(collectionId as string);
    setCollectionStats(response);
  };

  const fetchMoreAssets = async () => {
    if (!assetsOffset) return;

    const response = (await fetchAssets(assetsOffset)) || [];
    setAssets([...assets, ...response]);
  };

  const refreshLatestAssets = async () => {
    const response = (await fetchAssets(0)) || [];

    // only replace the the most recent results
    // prevents the whole list from getting reset if the user has scrolled down
    setAssets([...response, ...assets.slice(response.length, assets.length)]);
    setAssetsLastUpdated(new Date());
  };

  const fetchTraits = async () => {
    if (!collectionId) return;

    let response = await readCollectionTraits(collectionId as string);
    setTraits(response);
  };

  const buildAssetParams = (offset?: number) => {
    let params = { filters: {} } as ListAssetParams;

    params.filters.collectionId = collectionId as string;
    if (onlyBuyNow) {
      params.filters.onlyBuyNow = true;
    }
    if (onlySansa) {
      params.filters.onlySourceSansa = true;
    }

    if (filteredToken && filteredToken !== "") {
      let formattedTokenId = filteredToken;
      if (
        collection?.platformFormat === PlatformFormat.ArtBlocks &&
        collection?.artBlocksProjectId
      ) {
        formattedTokenId = String(
          generateTokenId(
            Number(collection?.artBlocksProjectId),
            Number(filteredToken)
          )
        );
      }

      params.filters.tokenId = formattedTokenId;
    }

    if (assetSort.sortKey !== "FLOOR_PRICE_ASC") {
      params.sort = assetSort.sortKey;
    }

    if (Object.keys(selectedTraits).length !== 0) {
      let traitParams = {} as any;

      Object.keys(selectedTraits).map((traitType: string) => {
        traitParams[traitType] = [];

        Object.keys(selectedTraits[traitType]).map((traitValueString) => {
          const traitValue =
            selectedTraits?.[traitType]?.[traitValueString].value;
          traitParams[traitType].push(traitValue);
        });
      });

      params.filters.traits = traitParams;
    }

    if (Object.keys(selectedCustomTraits).length !== 0) {
      let customTraitParams = {} as any;

      Object.keys(selectedCustomTraits).map((traitType: string) => {
        customTraitParams[traitType] = [];

        Object.keys(selectedCustomTraits[traitType]).map((traitValueString) => {
          const traitValue =
            selectedCustomTraits?.[traitType]?.[traitValueString].value;
          customTraitParams[traitType].push(traitValue);
        });
      });

      params.filters.customTraits = customTraitParams;
    }

    params.limit = ASSET_LIMIT;
    params.offset = offset || 0;

    return params;
  };

  const addTraitFilter = (
    traitType: string,
    value: string | number | boolean
  ) => {
    let newSelectedTraits = { ...selectedTraits };
    if (!(traitType in newSelectedTraits)) newSelectedTraits[traitType] = {};

    newSelectedTraits[traitType][`${value}`] = { value };
    setSelectedTraits(newSelectedTraits);
    setExploredTrait(null);
  };

  const removeTraitFilter = (
    traitType: string,
    value: string | number | boolean
  ) => {
    let newSelectedTraits = { ...selectedTraits };
    // Delete the trait
    delete newSelectedTraits[traitType][`${value}`];

    // check if trait category is empty and delete also
    if (Object.keys(newSelectedTraits[traitType]).length === 0) {
      delete newSelectedTraits[traitType];
    }

    setSelectedTraits(newSelectedTraits);
  };

  const addCustomTraitFilter = (
    traitType: string,
    value: string | number | boolean
  ) => {
    let newSelectedCustomTraits = { ...selectedCustomTraits };
    if (!(traitType in newSelectedCustomTraits))
      newSelectedCustomTraits[traitType] = {};

    newSelectedCustomTraits[traitType][`${value}`] = { value };
    setSelectedCustomTraits(newSelectedCustomTraits);
    setExploredTrait(null);
  };

  const removeCustomTraitFilter = (
    traitType: string,
    value: string | number | boolean
  ) => {
    let newSelectedCustomTraits = { ...selectedCustomTraits };
    // Delete the trait
    delete newSelectedCustomTraits[traitType][`${value}`];

    // check if trait category is empty and delete also
    if (Object.keys(newSelectedCustomTraits[traitType]).length === 0) {
      delete newSelectedCustomTraits[traitType];
    }

    setSelectedCustomTraits(newSelectedCustomTraits);
  };

  const handleAssetsLoadMore = () => {
    setAssetsOffset(assetsOffset + ASSET_LIMIT);
  };

  // Get relevant project sale data from the purchase machine
  const artblocksProjectSaleData = ProjectSaleManagerMachineContext.useSelector(
    (state) => {
      return state.context.project;
    }
  );

  const tabNavigation: Array<"items" | "activity" | "auctions" | "overview"> = [
    "items",
    "activity",
    "auctions",
  ];

  // Show the overview tab for all AB projects. Open AB flagship projects will have a
  // link to the AB flagship page, while all other open AB ecosystem projects will
  // include the purchase flow in the overview tab. Closed projects will show a button
  // to purchase on secondary. If we don't have minter configuration data, we won't show
  // the overview tab at all.
  const showOverview = Boolean(
    artblocksProjectSaleData &&
      artblocksProjectSaleData.minter_configuration?.minter
  );
  if (showOverview) {
    tabNavigation.unshift("overview");
  }

  let currentTab = router.query.tab as string | undefined;
  if (!currentTab && artblocksProjectSaleData?.complete) {
    currentTab = "items";
  } else if (!currentTab) {
    currentTab = tabNavigation[0];
  }

  const setCurrentTab = (
    tab: "overview" | "items" | "activity" | "auctions"
  ) => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          tab,
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  useEffect(() => {
    setFiltersOpen(false);
  }, [currentTab]);

  const activityFilterDefaults = [
    { name: "Sale", value: "sale", selected: true },
    { name: "Listing", value: "ask", selected: false },
  ];

  const [activityFilters, setActivityFilters] = useState<any>(
    activityFilterDefaults
  );

  const toggleActivityFilter = (value: string) => {
    let existingFilters = [...activityFilters];

    existingFilters.map((item) => {
      if (item.value === value) {
        item.selected = !item.selected;
      } else {
        item.selected = false;
      }
    });

    setActivityFilters(existingFilters);
  };

  const activityTypes = () => {
    let reservoirFilters = [] as any;
    activityFilters?.map((item: any) => {
      item.selected ? reservoirFilters.push(item.value) : "";
    });

    if (reservoirFilters?.length === 0) {
      return ["sale", "transfer"];
    }
    return reservoirFilters;
  };

  const handlePurchaseModalChanged = (isOpen: boolean) => {
    if (isOpen) {
      pauseAutoRefresh();
    } else {
      resumeAutoRefresh();
    }
  };

  const pauseAutoRefresh = () => {
    setIsAutoRefreshOn(false);
  };

  const resumeAutoRefresh = () => {
    setIsAutoRefreshOn(true);
    if (
      assetsLastUpdated < new Date(Date.now() - ASSET_REFRESH_INTERVAL_MS / 2)
    )
      refreshLatestAssets();
  };

  const toggleExploreTrait = (traitKey: string) => {
    setExploredTrait((prev: any) => (prev === traitKey ? null : traitKey));
  };

  useEffect(() => {
    setAssetSortOpen(false);
  }, [assetSort]);

  useEffect(() => {
    if (!onlyBuyNow && onlySansa) setOnlySansa(false);
  }, [onlyBuyNow]);

  useEffect(() => {
    if (!onlyBuyNow && onlySansa) setOnlyBuyNow(true);
  }, [onlySansa]);

  const renderTabContent = () => {
    switch (currentTab) {
      case "overview":
        return (
          <div className="min-h-screen">
            <CollectionOverview collection={collection} />
          </div>
        );
      case "items":
        return (
          <>
            {exploredTrait ? (
              <TraitExplore
                collectionId={collectionId}
                traitKey={exploredTrait}
                onFilterTraitClicked={addTraitFilter}
                onRemoveTraitClicked={() => setExploredTrait(null)}
              />
            ) : (
              <>
                <div className="px-8 pb-8 mt-10">
                  {assetsLoading ? (
                    <div className="flex flex-col items-center justify-center h-96">
                      <Loader
                        className="transition duration-300 text-neutral-500 animate-spin"
                        size={44}
                      />
                      <p className="mt-2 text-neutral-500">Loading Assets...</p>
                    </div>
                  ) : (
                    <>
                      {!assets?.length ? (
                        <EmptyState />
                      ) : (
                        <InfiniteScroll
                          dataLength={assets.length} //This is important field to render the next data
                          next={handleAssetsLoadMore}
                          hasMore={assetsHasMore}
                          loader={
                            <p className="mx-auto mt-8 text-sm text-center text-neutral-500">
                              Loading more...
                            </p>
                          }
                          endMessage={""}
                        >
                          <AssetGrid
                            rSidebarOpen={rightSidebarOpen}
                            size={assetGridSize}
                          >
                            {assets?.map((item: any) => {
                              return (
                                <AssetCard
                                  key={item._id}
                                  asset={item}
                                  showGenerator={
                                    generatorAsset === item._id &&
                                    item._id !== null
                                  }
                                  setGeneratorAsset={setGeneratorAsset}
                                  generatorAvailable={
                                    collection?.scriptGenerationAvailable
                                  }
                                  collection={collection}
                                  onPurchaseModalOpenChanged={
                                    handlePurchaseModalChanged
                                  }
                                  contain={aspectRatioIsVariable}
                                  showRanking={
                                    showRankings && !collection?.rarityDisabled
                                  }
                                />
                              );
                            })}
                          </AssetGrid>
                        </InfiniteScroll>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </>
        );
      case "activity":
        return (
          <div className="min-h-screen">
            <CollectionActivityTab
              filters={activityTypes()}
              collection={collection}
            />
          </div>
        );
      case "auctions":
        return (
          <div className="min-h-screen">
            <CollectionAuctionsTab
              collectionId={collectionId}
              rightSidebarOpen={rightSidebarOpen}
            />
          </div>
        );
    }
  };

  const tabNavigationToOptions = () => {
    let options = [] as any;

    tabNavigation.map((item) => {
      let tab = {
        title: item,
        selected: item === currentTab,
        action: () => setCurrentTab(item),
      };

      options.push(tab);
    });

    return options;
  };

  return (
    <div className="min-h-screen">
      <MetadataHead
        title={metadata?.title}
        description={metadata?.description}
        imageUrl={metadata?.imageUrl}
        canonicalUrl={metadata?.canonicalUrl}
      />

      <div className="relative flex h-full">
        <div className={`flex flex-col flex-grow w-full`}>
          <div className="relative w-full h-2">
            {!collection?.imageUrl ? null : (
              <div>
                <div
                  className="absolute z-10 w-full h-full"
                  style={{ backdropFilter: "blur(22px)" }}
                ></div>
                <div
                  style={{ backgroundImage: `url(${collection?.imageUrl})` }}
                  className="absolute w-full h-full bg-center bg-cover bg-neutral-100"
                ></div>
              </div>
            )}
          </div>

          <CollectionHeader
            id={collectionId}
            slug={collection?.slug}
            name={collection?.name}
            contractAddress={collection?.contractAddress}
            image={collection?.imageUrl}
            description={collection?.description}
            floorPrice={collectionStats?.floorPrice}
            volume={collectionStats?.volumeTotal}
            supply={collectionStats?.supplyTotal}
            owners={collectionStats?.ownersTotal}
            artistIds={collection?.artistIds}
            enableAlgoExplore={
              collection?.scriptGenerationAvailable && // script generation needs to be available (not available for non AB projects)
              !collection?.disableSampleGenerator
            }
            platformData={collection?.platformData}
            artistDisplayNotes={collection?.artistDisplayNotes}
            creativeCredit={collection?.creativeCredit}
            salesNotes={collection?.salesNotes}
            license={collection?.license}
            linkToLicense={collection?.linkToLicense}
          />

          <div className="w-full px-6 mt-6 overflow-x-scroll">
            <TabMenu options={tabNavigationToOptions()} />
          </div>

          {/* Show only filters if activity */}
          {currentTab === "activity" ? (
            <div className="items-center justify-between hidden px-8 mt-8 md:block dark:text-white">
              <div className="flex items-center gap-8">
                <button
                  onClick={() => setFiltersOpen((prev: boolean) => !prev)}
                  className="flex items-center gap-1 font-medium transition text-p-lg hover:opacity-50"
                >
                  {!filtersOpen ? (
                    <>
                      <Filter />
                      Filters
                    </>
                  ) : (
                    <>
                      <Chevron className="transform rotate-90" />
                      Hide Filters
                    </>
                  )}
                </button>
              </div>
            </div>
          ) : null}

          <div
            className={`${
              currentTab !== "items" ? "hidden" : "hidden md:flex"
            } px-8 mt-8 items-center justify-between dark:text-white`}
          >
            <div className="flex items-center gap-8">
              <button
                onClick={() => setFiltersOpen((prev: boolean) => !prev)}
                className="flex items-center gap-1 font-medium transition text-p-lg hover:opacity-50"
              >
                {!filtersOpen ? (
                  <>
                    <Filter className="dark:text-white" />
                    Filters
                  </>
                ) : (
                  <>
                    <Chevron className="transform rotate-90" />
                    Hide Filters
                  </>
                )}
              </button>

              <div style={{ maxWidth: "10rem" }}>
                <Input
                  value={filteredToken}
                  onChange={(e) => setFilteredToken(e.target.value)}
                  placeholder="Token ID"
                  leadingIcon={<Search />}
                />
              </div>
              <div>
                <p className="font-medium text-p-s">
                  {assetsTotal ? assetsTotal : "-"} Items
                </p>
                <div className="flex items-center text-p-xs">
                  <div className="relative w-2 h-2 mr-2">
                    <span
                      className={`block w-2 h-2 absolute rounded-full ${
                        isAutoRefreshOn
                          ? "bg-green-500 animate-ping"
                          : "bg-red-500"
                      }`}
                    ></span>
                    <span
                      className={`block w-2 h-2 absolute rounded-full ${
                        isAutoRefreshOn ? "bg-green-500" : "bg-red-500"
                      }`}
                    ></span>
                  </div>
                  <p className="text-neutral-600 dark:text-neutral-400">
                    Last checked:{" "}
                    <TimeSinceLabel lastUpdated={assetsLastUpdated} />
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setAssetGridSize?.(GridSize.Small)}
                  type="button"
                >
                  <ColumnsOne
                    className={`h-7 w-7 ${
                      assetGridSize === GridSize.Small
                        ? "text-neutral-900 dark:text-white"
                        : "text-neutral-400 dark:text-neutral-600"
                    }`}
                  />
                </button>
                <button
                  onClick={() => setAssetGridSize?.(GridSize.Large)}
                  type="button"
                >
                  <ColumnsTwo
                    className={`h-7 w-7 ${
                      assetGridSize === GridSize.Large
                        ? "text-neutral-900 dark:text-white"
                        : "text-neutral-400 dark:text-neutral-600"
                    }`}
                  />
                </button>
              </div>
              {!exploredTrait ? (
                <div className="relative z-20">
                  <Dropdown
                    weight="medium"
                    setOpen={() => setAssetSortOpen((prev: boolean) => !prev)}
                    title={assetSort.title}
                  />
                  <div
                    className={`${
                      assetSortOpen
                        ? "opacity-100"
                        : "opacity-0 translate-y-2 invisible"
                    } absolute transform transition top-10 right-0 w-64`}
                  >
                    <DropdownMenu {...sortOptions} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex">
            {currentTab !== "auctions" ? (
              <FilterSidebar
                open={filtersOpen}
                setOpen={setFiltersOpen}
                exploreTrait={toggleExploreTrait}
                selectedExploreTrait={exploredTrait}
                traits={traits}
                selectedTraits={selectedTraits}
                addTraitFilter={addTraitFilter}
                removeTraitFilter={removeTraitFilter}
                customTraits={customTraits}
                selectedCustomTraits={selectedCustomTraits}
                addCustomTraitFilter={addCustomTraitFilter}
                removeCustomTraitFilter={removeCustomTraitFilter}
                activityFilters={activityFilters}
                toggleActivityFilter={toggleActivityFilter}
                onlyBuyNow={onlyBuyNow}
                toggleBuyNow={() => setOnlyBuyNow((prev: boolean) => !prev)}
                onlySansa={onlySansa}
                toggleOnlySansa={() => setOnlySansa((prev: boolean) => !prev)}
                currentTab={currentTab}
                showRankings={showRankings}
                setShowRankings={setShowRankings}
                disableRankings={!!collection?.rarityDisabled}
              />
            ) : null}

            <CenterContainer leftOpen={filtersOpen}>
              {currentTab !== "auctions" ? (
                <div className="px-8 pt-6 md:hidden">
                  <Button
                    title="Filters"
                    className="w-full"
                    variant="secondary"
                    onClick={() => setFiltersOpen(true)}
                  >
                    <Filter />
                    <span>Filters</span>
                  </Button>
                </div>
              ) : null}
              {renderTabContent()}
            </CenterContainer>
          </div>
        </div>
        <div className="sticky top-0 flex-shrink-0 hidden w-2 h-screen border-l xl:block bg-neutral-100 dark:bg-neutral-800 dark:border-neutral-700 border-neutral-200"></div>
        <RightSidebar
          open={rightSidebarOpen}
          setOpen={setRightSidebarOpen}
          collection={collection}
        />
      </div>
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className="flex flex-col items-center justify-center h-96">
      <p className="text-neutral-500">No assets with these filters</p>
    </div>
  );
};

export const AssetGrid = ({
  children,
  rSidebarOpen,
  size,
}: {
  children: any;
  rSidebarOpen: boolean;
  size?: any;
}) => {
  const getGridSizeClasses = () => {
    if (rSidebarOpen) {
      if (size == GridSize.Large) {
        return "grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6";
      } else {
        return "grid-cols-1 sm:grid-cols-3 2xl:grid-cols-3";
      }
    } else {
      if (size == GridSize.Large) {
        return "grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6";
      } else {
        return "grid-cols-1 sm:grid-cols-3 2xl:grid-cols-4";
      }
    }
  };

  return (
    <div className={`grid ${getGridSizeClasses()} gap-x-8 gap-y-12`}>
      {children}
    </div>
  );
};
